import React from "react";
import {Button, Card} from "reactstrap";

import "./ChoiceCard.scss";

const ChoiceCard = ({ game, event, choice, disabled, makeChoice }) => {
    return (
        <Card className="choice-card">
            <h4>{choice.label}</h4>
            <div>{choice.description}</div>
            <div className="choice-button-row">
                <Button
                    className="choice-button"
                    disabled={disabled}
                    onClick={() => makeChoice(game, event, choice)}>
                    Choose
                </Button>
            </div>
        </Card>
    )
};

export default ChoiceCard;