export const play = (playerId) => {
    const element = document.getElementById(playerId);
    const isPlaying = () => element
        && element.currentTime > 0
        && !element.paused
        && !element.ended
        && element.readyState > 2;
    if (isPlaying()) {
        element.pause();
    } else {
        element.play();
    }
}