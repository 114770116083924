import React, {useState} from "react";

import { EventCard, ChoiceCard } from "./";

import {Button, Card} from "reactstrap";

import "./GameCard.scss";
import AgentList from "./AgentList";

const GameCard = ({ game, createNewEvent, makeChoice, completeEvent }) => {
    const [disabled, setDisabled] = useState(false);

    let currentEvent;
    if (game.game?.currentEvent) {
        currentEvent = (
            <Card className="game-card">
                <EventCard
                    game={game}
                    event={game.game.currentEvent}
                    disabled={disabled}
                    completeEvent={(game) => {
                        setDisabled(true);
                        completeEvent(game)
                            .then(() => setDisabled(false));
                    }}
                />
            </Card>
        );
    } else {
        currentEvent = (
            <Card className="new-event-card">
                <Button
                    className="new-event-button"
                    disabled={disabled}
                    onClick={() => {
                        setDisabled(true);
                        createNewEvent(game).then(() => setDisabled(false));
                    }}
                >
                    New Event
                </Button>
            </Card>
        );
    }
    let currentChoices;
    if (game.game.currentChoices) {
        currentChoices = game.game.currentChoices.map((choice, idx) =>
            <ChoiceCard
                game={game}
                event={game.game.currentEvent}
                choice={choice}
                disabled={disabled}
                key={`choice-${idx}`}
                makeChoice={(game, event, choice) => {
                    setDisabled(true);
                    makeChoice(game, event, choice)
                        .then(() => setDisabled(false));
                }} />
        );
    }

    return (
        <>
            <AgentList game={game} />
            {currentEvent}
            {currentChoices}
        </>
    );
}

export default GameCard;