import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth"

import * as Api from '../api/Api';

import { SpinnerPage } from "../common";
import { GamesList, NewGameModal } from "./";

import { auth } from "../firebase"

import './HomeContainer.scss';

const HomeContainer = () => {
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const [loadingCount, setLoadingCount] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [user, loading] = useAuthState(auth);

    const createNewGame = (name) => {
        return Api.createNewGame(user.accessToken, { name })
            .then((game) =>
                Api.createNewEvent(user.accessToken, game)
                    .then(() =>
                        Api.loadGames(user.accessToken)
                            .then(data => {
                                setGames(data.games);
                                navigate(`/game/${game.id}`);
                            }))
            );
    };

    const createNewEvent = (game) => {
        return Api.createNewEvent(user.accessToken, game)
            .then(() => Api.loadGames(user.accessToken)
                .then(data => setGames(data.games)));
    }

    const makeChoice = (game, event, choice) => {
        return Api.makeChoice(user.accessToken, { game, event, choice})
            .then(() => Api.loadGames(user.accessToken)
                .then(data => setGames(data.games)));
    }

    const completeEvent = (game) => {
        return Api.completeEvent(user.accessToken, game)
            .then(() => Api.loadGames(user.accessToken)
                .then(data => setGames(data.games)));
    }

    useEffect( () => {
        // no logged in user
        if (!user) {
            return;
        }
        setLoadingCount(1);

        Api.loadGames(user.accessToken)
            .then(data => {
                setGames(data.games);
                setLoadingCount(p => p - 1);
            });
    }, [user]);

    if (loading) {
        return <SpinnerPage />;
    } else if (user === null) {
        navigate("/login");
    } else if (loadingCount > 0) {
        return <SpinnerPage />;
    }

    return (
        <div className="home-container">
            <div className="nav-container welcome">
                <div className="nav-title"></div>
            </div>
            <div className="nav-spacer" />
            <GamesList
                games={games}
                onNewGame={() => setModalOpen(true)}
                createNewEvent={createNewEvent}
                makeChoice={makeChoice}
                completeEvent={completeEvent}
            />
            <NewGameModal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                createNewGame={createNewGame}
            />
        </div>
    );
}

export default HomeContainer;