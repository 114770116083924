// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Initialize Firebase
const app = initializeApp(JSON.parse(`{"apiKey":"AIzaSyDF73MiRGLW7qAzHsEQtTVO9KDHXfMIVmQ","authDomain":"tales-of-the-hearth-d53c0.firebaseapp.com","projectId":"tales-of-the-hearth-d53c0","storageBucket":"tales-of-the-hearth-d53c0.appspot.com","messagingSenderId":"658644139912","appId":"1:658644139912:web:c9879df1fba87a50f5043d"}`));

const auth = getAuth(app);
auth.useDeviceLanguage();

export { auth, GoogleAuthProvider };


