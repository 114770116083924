import {
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    createUserWithEmailAndPassword
} from "firebase/auth";
import { auth } from "../firebase";

export async function logout() {
    await signOut(auth);
}

export async function login({ username: email, password }) {
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            return user.accessToken;
        })
        .catch(({ message: errorMessage }) => {
            console.error('Firebase sign-in error', errorMessage);
            return async () => { return [null, errorMessage]; }
        });
}

export async function loginWithPopupProvider(providerClass) {
    return signInWithPopup(auth, new providerClass())
        .then(result => {
            const credential = providerClass.credentialFromResult(result);
            const user = result.user;
            console.log('signinWithPopop success', user, credential);
            return user.accessToken;
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData?.email;
            // The AuthCredential type that was used.
            const credential = providerClass.credentialFromError(error);

            console.error(`Firebase sign-in error code=${errorCode} message=${errorMessage} email=${email}`, credential);
            return async () => { return [null, errorMessage]; }
        });
}

export async function register({ email, password}) {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up
            const user = userCredential.user;
            return user.getIdToken();
        })
        .catch(({ code: errorCode, message: errorMessage }) => {
            console.error('Firebase create error', errorCode, errorMessage);
        });
}

export async function validateToken(token) {
    return await fetch(process.env.REACT_APP_BACKEND_HOST + `/api/v1/status`, {
        method: 'POST',
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.status >= 200 && response.status < 300;
    });
}



export function loadGames(token) {
    const path = `/api/v1/game`;
    console.log(`Calling fetch ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json());
}

export function createNewGame(token, options) {
    const payload = { name: options.name }

    const path = `/api/v1/game`;
    console.log(`Calling fetch POST ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
    }).then(response => response.json());
}

export function createNewEvent(token, options) {
    const path = `/api/v1/game/${options.id}/event`;
    console.log(`Calling fetch POST ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json());
}

export function makeChoice(token, { game, choice}) {
    const payload = { choice: choice.name };
    const path = `/api/v1/game/${game.id}/choice`;
    console.log(`Calling fetch POST ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
    }).then(response => response.json());
}

export function completeEvent(token, game) {
    const path = `/api/v1/game/${game.id}/currentEvent`;
    console.log(`Calling fetch DELETE ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, {
        mode: "cors",
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
}