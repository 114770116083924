// eslint-disable-next-line no-unused-vars
import React, {useEffect} from "react";
import * as Api from "../api/Api";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()

    useEffect(() => {
        Api.logout()
            .then(() => navigate("/"));

    }, [navigate]);

    return null;
};

export default Logout;