import AgentCard from "./AgentCard";

import "./AgentList.scss";

const AgentList = ({ game }) => {
    return (
        <div className="agent-list">
            {game.game.agents.map(agent =>
                <AgentCard
                    key={agent.name}
                    agent={agent}
                    game={game}
                />
            )}
        </div>
    )
};

export default AgentList;