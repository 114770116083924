import {GameCard} from "./index";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";
import * as Api from "../api/Api";
import {SpinnerPage} from "../common";

import "./HomeContainer.scss";

const GameContainer = () => {
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const [loadingCount, setLoadingCount] = useState(0)
    const [user, loading] = useAuthState(auth);

    let { id } = useParams();

    const createNewEvent = (game) => {
        return Api.createNewEvent(user.accessToken, game)
            .then(() => Api.loadGames(user.accessToken)
                .then(data => setGames(data.games)));
    }

    const makeChoice = (game, event, choice) => {
        return Api.makeChoice(user.accessToken, { game, event, choice})
            .then(() => Api.loadGames(user.accessToken)
                .then(data => setGames(data.games)));
    }

    const completeEvent = (game) => {
        return Api.completeEvent(user.accessToken, game)
            .then(() => Api.loadGames(user.accessToken)
                .then(data => setGames(data.games)));
    }

    useEffect( () => {
        // no logged in user
        if (!user) {
            return;
        }
        setLoadingCount(1);

        Api.loadGames(user.accessToken)
            .then(data => {
                setGames(data.games);
                setLoadingCount(p => p - 1);
            });
    }, [user]);

    console.log('games', games);

    if (loading) {
        return <SpinnerPage />;
    } else if (user === null) {
        navigate("/login");
    } else if (loadingCount > 0) {
        return <SpinnerPage />;
    } else if (!games.find(game => game.id === id)) {
        return <SpinnerPage />;
    }

    return (
        <div className="home-container">
            <div className="nav-container welcome">
                <div className="nav-title"></div>
            </div>
            <div className="nav-spacer" />
            <div className="game-card-container">
                <GameCard
                    game={games.find(game => game.id === id)}
                    createNewEvent={createNewEvent}
                    makeChoice={makeChoice}
                    completeEvent={completeEvent}
                />
            </div>
        </div>
    );
};

export default GameContainer;