import React from "react";

import {Card, Button} from "reactstrap";

import * as utils from "../utils/utils";
import playImg from "../assets/play.png";

import "./EventCard.scss";

const EventCard = ({ game, event, disabled, completeEvent }) => {
    const eventAudioSource = `${process.env.REACT_APP_BACKEND_HOST}/api/v1/audio?id=${game.id}&eventId=${event.id}`;
    const outcomeAudioSource = eventAudioSource + "&target=OUTCOME";

    const outcome = game.game.history.find(item => item.type === "EventOutcome" && item?.eventId === event.id)?.description;

    let eventCard;
    let outcomeCard;
    let playButton;

    if (outcome) {
        eventCard = <p>{event.description}</p>;

        outcomeCard = (
            <Card className="outcome-card">
                <div className="event-header">
                    <h4>Outcome</h4>
                    <Button className="play-button" onClick={() => utils.play('outcome-player')}>
                        <img className="play-button-img" src={playImg}/>
                    </Button>
                </div>
                <p>{outcome}</p>
                <audio id="outcome-player">
                    <source src={outcomeAudioSource}/>
                </audio>
                <div className="outcome-button-row">
                    <Button
                        className="outcome-button"
                        disabled={disabled}
                        onClick={() => completeEvent(game)}
                    >
                        Time Passes
                    </Button>
                </div>
            </Card>
    );
    } else {
        playButton = (
            <Button className="play-button" onClick={() => utils.play('player')}>
                <img className="play-button-img" src={playImg} />
            </Button>
        );
        eventCard = <>
            <p>{event.description}</p>
            <audio id="player">
                <source src={eventAudioSource}/>
            </audio>
        </>
    }

    return (
        <Card className="event-card">
            <div className="event-header">
                <h3>{event.name}</h3>
                {playButton}
            </div>
            {eventCard}
            {outcomeCard}
        </Card>
    )
};

export default EventCard;