import React from "react";

import {Button, Card, PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap";

import playImg from "../assets/play.png";
import messages from '../assets/messages.png';
import stats from '../assets/stats.png';

import * as utils from '../utils/utils';

import "./AgentCard.scss";

const images = require.context('../assets/agents', true);
const imageList = images.keys().map(image => images(image));

const AgentCard = ({ agent, game }) => {
    const genderKey = agent.gender?.toLowerCase() || 'non_binary';
    const typeKey = agent.type?.toLowerCase();
    let numKey = 1;

    if (genderKey !== "non_binary") {
        numKey = ((agent.seed % 2) + 1).toString();
    }

    const filename = `/static/media/${genderKey}_${typeKey}_${numKey}`;
    const imgUrl = imageList.find(i => i.toLowerCase().startsWith(filename));

    console.log(agent);

    //const outcome =
    const event = game.game.currentEvent;
    const outcome = game.game.history.find(item => item.type === "EventOutcome" && item?.eventId === event?.id);

    const key = (type) => `agent-${agent.name.replaceAll(" ", "-")}-${type}`;

    let popover;
    let audioControl;
    const actions = [];
    actions.push(
        <Button id={key("stats")} key={key("stats")}>
            <img src={stats} alt="Agent stats"/>
        </Button>
    );
    if (outcome?.description && outcome.speakOut[agent.name]) {
        actions.push(
            <Button id={key("messages")} key={key("messages")}>
                <img src={messages} alt="Agent Has Something To Say"/>
            </Button>
        );
        popover = (
            <UncontrolledPopover
                className="speak-out-popover"
                placement="bottom"
                target={key("messages")}
                trigger="legacy"
            >
                <PopoverHeader>
                    {agent.name}
                    <Button className="play-button" onClick={() => utils.play(key("player"))}>
                        <img className="play-button-img" src={playImg}/>
                    </Button>
                </PopoverHeader>
                <PopoverBody>
                    {outcome.speakOut[agent.name]}
                </PopoverBody>
            </UncontrolledPopover>
        );
        const speakOutAudioSource = `${process.env.REACT_APP_BACKEND_HOST}/api/v1/audio?id=${game.id}&eventId=${event.id}&target=SPEAK_OUT&agent=${agent.name}`;

        audioControl = (
            <audio id={key("player")}>
                <source src={speakOutAudioSource}/>
            </audio>
        )
    }

    return (
        <>
            <Card className="agent-card">
                <div className="agent-portrait">
                    <img src={imgUrl} alt={filename} />
                    <p>{agent.name}, {agent.age}</p>
                    <p>{agent.job}</p>
                </div>
                <div className="agent-action-bar">
                    {actions}
                </div>
            </Card>
            {popover}
            {audioControl}
        </>
    );
};

export default AgentCard;