import React, {useState} from "react";

import {useNavigate} from "react-router-dom";

import {Button} from "reactstrap";
import * as Api from "../api/Api";

import "./Login.scss";


const Register = () => {
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [registerError, setRegisterError] = useState({})

    const handleSubmit = async (e) => {
        e.preventDefault()
        await Api.register({
            email,
            password
        }).then(userToken => {
            if (userToken) {
                navigate("/");
                return;
            }
            setRegisterError({ message: "Something went wrong. Try again later!" });
        })
    }

    let err = null;
    if (registerError?.message?.length) {
        err = (
            <div className="register-error">
                {registerError.message}
            </div>
        )
    }

    return (
        <div className="base-container">
            <div className="login-container box filled-opaque">
                <div className="header">
                    Tales of the Hearth
                </div>
                <div className="content">
                    <div className="image">
                        {err}
                    </div>
                    <div className="form">
                        <div className="form-group">
                            <input type="text" name="email" placeholder="email" onChange={(e)=> setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <input type="password" name="password" placeholder="password" onChange={(e)=> setPassword(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Button className="btn" onClick={handleSubmit}>
                        Register
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Register;