import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input} from "reactstrap";
import {useState} from "react";

import "./NewGameModal.scss";

const NewGameModal = ({ isOpen, closeModal, createNewGame}) => {
    const [name, setName] = useState("");
    const [disabled, setDisabled] = useState(false);
    return (
        <Modal
            isOpen={isOpen}
            toggle={() => { if (!disabled) closeModal()}}
        >
            <ModalHeader>Greetings, Governor</ModalHeader>
            <ModalBody>
                <p>We are excited for your leadership. Before we begin, what shall we call you?</p>
                <Input
                    disabled={disabled}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </ModalBody>
            <ModalFooter>
                <Button disabled={disabled} onClick={closeModal}>
                    Cancel
                </Button>{' '}
                <Button
                    className="modal-ok-button"
                    disabled={name.trim().length <= 0 || disabled}
                    onClick={() => {
                        setDisabled(true);
                        createNewGame(name.trim())
                            .then(() => {
                                setDisabled(false);
                                closeModal()
                            })
                    }}
                >
                    Begin Your Governorship
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default NewGameModal;