import React, {useState} from "react";

import {Link, useLocation, useNavigate} from "react-router-dom";

import {Button} from "reactstrap";

import * as Api from "../api/Api";


import { GoogleAuthProvider } from "firebase/auth";
import SignInWithGoogleButton from "./SignInWithGoogleButton";

import "./Login.scss";

const Login = ({ standalone }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [err, setErr] = useState({});

    const completeLogin = (token, error) => {
        if (error) {
            setErr({ error });
            return;
        } else {
            setErr({});
        }
        if (pathname === '/logout' || standalone) {
            navigate("/");
        }
    }

    const handleGoogleSubmit = async (e) => {
        e.preventDefault();
        await Api.loginWithPopupProvider(GoogleAuthProvider)
            .then(completeLogin);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await Api.login({
            username,
            password
        }).then(completeLogin);
    }

    let errorMessage;
    if (err?.error?.length) {
        errorMessage = <span className="error">Something went wrong. Try again later.</span>
    }

    return (
        <div className="base-container">
            <div className="login-container box filled-opaque">
                <div className="header">
                    Tales of the Hearth
                </div>
                <div className="content">
                    <div className="image">
                        {errorMessage}
                    </div>
                    <div className="form">
                        <div className="form-group">
                            <input type="text" required name="username" autoComplete="off" placeholder="username"
                                   onChange={(e) => setUsername(e.target.value)}/>
                        </div>
                        <div className="form-group">
                            <input type="password" required name="password" autoComplete="off" placeholder="password"
                                   onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="footer-login-methods">
                        <Button className="login-button" onClick={handleSubmit}>
                            Login
                        </Button>
                        <SignInWithGoogleButton handleClick={handleGoogleSubmit}/>
                    </div>
                    <div className="register">
                        <span>New to Tales?</span>
                        <Link to="/register">Register Here</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;