import React from 'react';

import {Button, Card} from "reactstrap";
import Intro from "./Intro";
import {useNavigate} from "react-router-dom";

import "./GamesList.scss";

const GamesListItem = ({ game }) => {
    const navigate = useNavigate();
    return (
        <Card className="game-list-item">
            <div className="game-list-item-row">
                <p>Governor {game.game.governorName}</p>
                <Button
                    className="game-list-resume-button"
                    onClick={() => navigate(`/game/${game.id}`)}
                >
                    Resume
                </Button>
            </div>
        </Card>
    );
};

const NewGameItem = ({ onNewGameClick }) => {
    return (
        <Card className="game-list-item new-game">
            <div className="game-list-item-row">
                <Button
                    className="new-game-button"
                    onClick={() => onNewGameClick()}
                >
                    New Game
                </Button>
            </div>
        </Card>
    );
};

const GamesList = ({ games, onNewGame }) => {
    if (!games?.length) {
        return (
            <div className="no-games">
                <div className="welcome-info box">
                    <h3>Welcome to Tales of the Hearth</h3>
                    <Intro />
                    <div className="get-started-button-row">
                        <Button
                            className="get-started-button"
                            onClick={() => onNewGame()}>
                            New Game
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
    return (
        <div className="game-list">
            {games.map(game =>
                <GamesListItem game={game} key={`game-list-item-${game.id}`} />
            )}
            <NewGameItem onNewGameClick={onNewGame}/>
        </div>
    );
}

export default GamesList;